.ag-cell-wrapper {
  height: 100%;
}

.checkbox-selection-cell {
  input {
    width: 55px !important;
    height: 36px !important;
    margin-left: -18px !important;
    margin-top: -9px !important;
  }
}

.checkbox-selection-cell.ag-cell-focus {
  border: none !important;
  border-right: 1px solid #e2e2e2 !important;
  background-color: inherit !important;
}
.ag-row-selected {
  background-color: inherit !important;
}

.related-cell-orange {
  background-color: #ff98000d;
  color: #FF9800;
  border-bottom: 1px solid #FF9800 !important;
}
.related-cell-red {
  background-color: #B71C1C0d;
  color: #B71C1C;
  border-bottom: 1px solid #B71C1C !important;
}
.related-cell-purple {
  background-color: #433FB50d;
  color: #433FB5;
  border-bottom: 1px solid #433FB5 !important;
}

.subSectionHeader {
  font-size: 12px !important;
}

.reduced-padding-cell {
  padding-left:  5px !important;
  padding-right: 5px !important;
}

.cells-page-grid-wrapper .ag-header-cell,
.cells-page-grid-wrapper .ag-header-group-cell {
  padding-left:  5px !important;
  padding-right: 5px !important;
}

.matrix-page-grid-wrapper .ag-header-cell,
.matrix-page-grid-wrapper .ag-header-group-cell {
  padding-left:  5px !important;
  padding-right: 5px !important;
}
